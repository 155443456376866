/**
 * Compare two object in deep
 * @param {Object} x
 * @param {Object} y
 * @returns {false|this is string[]|boolean}
 */
export function deepEqual(x, y) {
    const ok = Object.keys,
        tx = typeof x,
        ty = typeof y
    return x && y && tx === "object" && tx === ty
        ? ok(x).length === ok(y).length &&
              ok(x).every((key) => deepEqual(x[key], y[key]))
        : x === y
}

export function downloadExcel(filename, data) {
    const date = new Date()
    // format date to have DD_MM_YYYY
    const day = date.getDate().toString().padStart(2, "0")
    const month = (date.getMonth() + 1).toString().padStart(2, "0")
    const year = date.getFullYear()

    const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    })

    const url = URL.createObjectURL(blob)

    const link = document.createElement("a")
    link.href = url
    link.download = filename + "_" + day + "_" + month + "_" + year + ".xlsx"
    document.body.appendChild(link)

    link.click()

    URL.revokeObjectURL(url)
    document.body.removeChild(link)
}

// Return if user is french or not, building on its country id
export function isFrenchUser(value) {
    switch (value) {
        case "1":
        case "12":
        case "13":
        case "14":
        case "16":
        case "17":
        case "18":
        case "38":
        case "42":
        case "43":
        case "44":
        case "45":
            return true
        default:
            return false
    }
}
